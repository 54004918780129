$("#contact-submit").click(function(){
        var contactName = $('#username').val();
        var contactEmail =$('#email').val();
        var contactPhone =$('#phone').val();
        var comments = $('#comments').val();
        if(contactName=='' || contactEmail=='' || contactPhone=='' || comments==''){
            $('#error_message').html('*All fields are required');
            console.log("error")
        }else{
            $('#error_message').html('');
            console.log("Done")
            $.ajax({
                url:'contact-mail.php',
                method:'POST',
                data:{contactName:contactName,contactEmail:contactEmail,contactPhone:contactPhone,comments:comments,},
                success:function(data){
                    $("form").trigger("reset");
                    $('#success_message').fadeIn().html(data);
                    $('#success_message').fadeOut(5000);
                }
            });
        }
    });
 
 $(".slides .slide a.slide-more").eq(0).attr("href", "/gallery.html#filter=.spaces");        
 $(".slides .slide a.slide-more").eq(1).attr("href", "/gallery.html#filter=.food");
 $(".slides .slide a.slide-more").eq(2).attr("href", "/gallery.html#filter=.product");
 $(".slides .slide a.slide-more").eq(3).attr("href", "/gallery.html#filter=.nature");        
 $(".slides .slide a.slide-more").eq(4).attr("href", "/gallery.html#filter=.people");
 $(".slides .slide a.slide-more").eq(5).attr("href", "/gallery.html#filter=.wedding");
 // store filter for each group

 var filters = {};

 $('.filters').on('click', '.button', function (event) {

         var $button = $(event.currentTarget);

         // get group key
         var $buttonGroup = $button.parents('.button-group');

         var filterGroup = $buttonGroup.attr('data-filter-group');

         // set filter for group
         filters[filterGroup] = $button.attr('data-filter');

         // combine filters
         var filterValue = concatValues(filters);

         // set filter for Isotope
         $grid.isotope({
                 filter: filters
         });

 });


 // change is-checked class on buttons
 $('.button-group').each(function (i, buttonGroup) {
         var $buttonGroup = $(buttonGroup);
         $buttonGroup.on('click', 'button', function (event) {
                 $buttonGroup.find('.is-checked').removeClass('is-checked');
                 var $button = $(event.currentTarget);
                 $button.addClass('is-checked');
         });
 });


 // flatten object by concatting values
 function concatValues(obj) {
         var value = '';
         for (var prop in obj) {
                 value += obj[prop];
         }
         return value;
 }


 $(document).ready(function () {
         $('[data-fancybox="gallery"]').fancybox({
                 // Options will go here
                 protect: false,
         });
 });


 // filter functions
 var filterFns = {
         // show if number is greater than 50
         numberGreaterThan50: function () {
                 var number = $(this).find('.number').text();
                 return parseInt(number, 10) > 50;
         },
         // show if name ends with -ium
         ium: function () {
                 var name = $(this).find('.name').text();
                 return name.match(/ium$/);
         }
 };



 function getHashFilter() {
         // get filter=filterName
         var matches = location.hash.match(/filter=([^&]+)/i);
         var hashFilter = matches && matches[1];
         return hashFilter && decodeURIComponent(hashFilter);
 }

 $(document).ready(function () {
         // init Isotope
         var $grid = $('.grid');


         // bind filter button click
         var $filterButtonGroup = $('.filter-button-group');
         $filterButtonGroup.on('click', 'button', function () {
                 var filterAttr = $(this).attr('data-filter');
                 // set filter in hash
                 location.hash = 'filter=' + encodeURIComponent(filterAttr);
         });

         var isIsotopeInit = false;

         function onHashchange() {
                 var hashFilter = getHashFilter();
                 if (!hashFilter && isIsotopeInit) {
                         return;
                 }
                 isIsotopeInit = true;
                 jQuery(function () {
                         // filter isotope
                         var $container = jQuery('.grid');

                         $container.imagesLoaded(function () {
                                 $grid.isotope({
                                         itemSelector: '.grid-item',
                                         percentPosition: true,
                                         masonry: {
                                                 columnWidth: '.grid-sizer'
                                         },
                                         // use filterFns
                                         filter: filterFns[hashFilter] || hashFilter
                                 });
                         });
                 });
                 // set selected class on button
                 if (hashFilter) {
                         $filterButtonGroup.find('.is-checked').removeClass('is-checked');
                         $filterButtonGroup.find('[data-filter="' + hashFilter + '"]').addClass('is-checked');
                 }
         }

         $(window).on('hashchange', onHashchange);

         // trigger event handler to init Isotope
         onHashchange();

 });
